import React from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Team, Banner, Footer } from '../components/common';
import { AboutPageHeader } from 'src/components/PageHeaders';

const AboutPage = () => (
  <>
    <SEO title="Our Origins" />
    <AboutPageHeader />

    <StyledValuesSection>
      <div className="left">
        <h4 className="section-header header">Our Values</h4>

        <div className="subt4ext">
          <p>
            Beautiful Stories is a production company that develops and produces film and
            photography. We tell beautiful stories from the African perspective. Through our work,
            we seek to inspire Africans on the continent and in the diaspora to be proud of their
            heritage, history and culture.
          </p>
          <br />
          <p>
            Re-creating, re-visualizing and re-defining African stories in today’s modern
            perspective; journeying through where we were and who we’ve become. We believe that if
            we're the custodians of our narrative & story we could possibly eliminate the idea of
            “single stories” and begin to realize our potential.
          </p>
        </div>
      </div>

      <div className="right">
        <h4 className="section-header header">Our Process</h4>

        <div className="top">
          <div className="history">
            <h4 className="header">Development</h4>
            <p>
              Every great project begins with good creative direction and concept development to
              help assist you create the best ideas for your brand.
            </p>
          </div>

          <div className="facts">
            <h4 className="header">Pre-production</h4>
            <p>
              Through collaborative efforts, we scout the best locations and cast the best models
              for projects.
            </p>
          </div>
        </div>

        <div className="top">
          <div className="history">
            <h4 className="header">Production</h4>
            <p>
              The focus of this phase includes directing, producing and shooting of content. The
              most common misconception is that content only involve production however it is one of
              four processes.
            </p>
          </div>

          <div className="facts">
            <h4 className="header">Post-production</h4>
            <p>
              Video editing, sound design, sound engineering and color grading makes up the final
              facet of our creative process. The captured imagery is processed into a final product.
            </p>
          </div>
        </div>
      </div>
    </StyledValuesSection>
    <Team />
    <Banner />
    <Footer />
  </>
);

export default AboutPage;

const StyledValuesSection = styled.section`
  margin: 3rem 0;
  padding: 5rem 15rem;
  display: flex;

  @media only screen and (max-width: 600px) {
    padding: 2rem;
    flex-wrap: wrap;
  }

  .section-header {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }

  .left {
    flex-basis: 50%;
    margin-right: 5rem;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 4rem;
    }
    .subtext {
      margin: 0.5rem 0;
      color: ${({ theme }) => theme.colors.darkBlueLighter2x};

      p {
        margin-bottom: 1.5rem;
        line-height: 28px;
      }
    }
  }

  .right {
    flex-basis: 50%;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }
    .top {
      display: flex;

      .history,
      .facts {
        flex-basis: 50%;
        margin-bottom: 1rem;
      }

      .history {
        margin-right: 1rem;
      }

      .facts {
        margin-left: 1rem;
      }

      h4 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        color: ${({ theme }) => theme.colors.darkBlueLighter3x};
      }

      p {
        font-size: 0.9rem;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.greyPrimary};
      }
    }
  }
`;
